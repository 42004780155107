import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/member/rewardcardValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";

import { RewardCardDataContext } from "../rewardCard";

axios.defaults.baseURL = API;

function UpdateRewardCard(props) {

    const {dataList, setDataList, Data } = useContext(RewardCardDataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    async function updateLocation() {
        let token = localStorage.getItem("token");
        var data = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            TEL: values.TEL,
            RewardCardNO: values.RewardCardNO,
            Email: values.Email,
            Identification: values.Identification,
        }
        await axios.put(`/updateRewardCard/${values.RewardCardID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < dataList.length; i++) {
                if (dataList[i].RewardCardID == values.RewardCardID) {
                    dataList[i].FirstName = values.FirstName;
                    dataList[i].LastName = values.LastName;
                    dataList[i].RewardCardNO = values.RewardCardNO;
                    dataList[i].TEL = values.TEL;
                    dataList[i].Email = values.Email;
                    dataList[i].Identification = values.Identification;
                    localStorage.setItem("rewardcardData", JSON.stringify(dataList));
                    break;
                }
            }  
            setDataList(dataList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("RewardCardID", "");
        handleChange("FirstName", "");
        handleChange("LastName", "");
        handleChange("TEL", "");
        handleChange("RewardCardNO", "");
        handleChange("Email", "");
        handleChange("Identification", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateLocation, validate);

    useEffect(() => {
        Data.forEach(value => {
            handleChange("RewardCardID", value.RewardCardID);
            handleChange("FirstName", value.FirstName);
            handleChange("LastName", value.LastName);
            handleChange("RewardCardNO", value.RewardCardNO);
            handleChange("TEL", value.TEL);
            handleChange("Email", value.Email);
            handleChange("Identification", value.Identification);
            handleChange("CheckRewardCardNO", "");
        });
    }, [Data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂຂໍ້ມູນບັດ RewardCard</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື້</span>
                                <input className={`input form-control ${errors.FirstName && 'is-invalid'}`} type="text" name="FirstName" onChange={(e) => handleInputChange(e)} value={values.FirstName || ''} required />
                                {errors.FirstName && (
                                    <p className="invalid-feedback">{errors.FirstName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ນາມສະກູນ</span>
                                <input className={`input form-control ${errors.LastName && 'is-invalid'}`} type="text" name="LastName" onChange={(e) => handleInputChange(e)} value={values.LastName || ''} required />
                                {errors.LastName && (
                                    <p className="invalid-feedback">{errors.LastName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>RewardCardNO:</span>
                                <input className={`input form-control ${errors.RewardCardNO && 'is-invalid'}`} type="text" name="RewardCardNO"  value={values.RewardCardNO || ''} disabled />
                                {errors.RewardCardNO && (
                                    <p className="invalid-feedback">{errors.RewardCardNO}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ເບີໂທ:</span>
                                <input className={`input form-control ${errors.TEL && 'is-invalid'}`} type="text" name="TEL" onChange={(e) => handleInputChange(e)} value={values.TEL || ''} required />
                                {errors.TEL && (
                                    <p className="invalid-feedback">{errors.TEL}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>Email:</span>
                                <input className={`input form-control ${errors.Email && 'is-invalid'}`} type="text" name="Email" onChange={(e) => handleInputChange(e)} value={values.Email || ''} required />
                                {errors.Email && (
                                    <p className="invalid-feedback">{errors.Email}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Identification:</span>
                                <input className={`input form-control ${errors.Identification && 'is-invalid'}`} type="text" name="Identification" onChange={(e) => handleInputChange(e)} value={values.Identification || ''} required />
                                {errors.Identification && (
                                    <p className="invalid-feedback">{errors.Identification}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateRewardCard;
