
import React, { useState, useEffect } from "react";
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/loginValidate';
import axios from "axios";
import { API } from "../../../services/api";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';

axios.defaults.baseURL = API;

function Employee() {

  const [data, setData] = useState([])
  
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setData(userRole);
    }
  }, []);

  return (
    <div className="formContent">
        <div className="Header">
            <div className="title">
                <span>ຂໍ້ມູນ ພະນັກງານ</span>
            </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" />
            <button className="btn btn-primary">ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ID</th>
                <th>ຊື່</th>
                <th>ນາມສະກູນ</th>
                <th>ເບີໂທ</th>
                <th>ສາຂາ</th>
                <th>ໜ້າທີ່</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.name}</td>
                        <td >{x.surname}</td>
                        <td >{x.telephone}</td>
                        <td >{x.LocationName}</td>
                        <td >{x.role}</td>
                        <td >
                          {/* <button className="btn btn-secondary" value={x.id} type="button" onClick={() => openUpdate(x.id, x.Gender, x.FirstName, x.LastName, x.Email, x.Phone, x.Address)}>Edit</button> */}
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default Employee;
