import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/brandValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { BrandContext } from "../brand";

axios.defaults.baseURL = API;

function UpdateBrand(props) {

    const {dataList, setDataList, data, ProductTypeList, CategoryList} = useContext(BrandContext);
    const [categoryData, setCategoryData] = useState([]);
    const handleInputStatusChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.Brand.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckBrand", results[0].Brand);
        else
            handleChange("CheckBrand", "");
    }

    const handleSelectProductType = (e) => {
        var data = ProductTypeList.filter(function (item) {
            return item.ProductType == e.target.value
        })
        if (data.length > 0) {
            var obj = CategoryList.filter(function (item) {
                return item.ProductTypeID == data[0].ProductTypeID
            })
            setCategoryData([]);
            handleChange("Category", "");
            handleChange("CategoryID", "");
            setCategoryData(obj);
            handleChange("ProductType", data[0].ProductType);
            handleChange("ProductTypeID", data[0].ProductTypeID);
        } else {
            setCategoryData([]);
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
        }
    };

    const handleSelectCategory = (e) => {
        var data = categoryData.filter(function (item) {
            return item.Category == e.target.value
        })
        if (data.length > 0) {
            handleChange("Category", data[0].Category);
            handleChange("CategoryID", data[0].CategoryID);
        } else {
            handleChange("Category", "");
            handleChange("CategoryID", "");
        }
    };

    async function update() {
        let token = localStorage.getItem("token");
        var data = {
            ProductTypeID: values.ProductTypeID,
            CategoryID: values.CategoryID,
            Brand: values.Brand,
        }
        await axios.put(`/updateBrand/${values.BrandID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            for (var i=0; i < dataList.length; i++) {
                if (dataList[i].BrandID == values.BrandID) {
                    dataList[i].ProductTypeID = values.ProductTypeID;
                    dataList[i].ProductType = values.ProductType;
                    dataList[i].Category = values.Category;
                    dataList[i].CategoryID = values.CategoryID;
                    dataList[i].Brand = values.Brand;
                    localStorage.setItem("brandData", JSON.stringify(dataList));
                    break;
                }
            }  
            setDataList(dataList);
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("ProductTypeID", "");
        handleChange("ProductType", "");
        handleChange("CategoryID", "");
        handleChange("Category", "");
        handleChange("BrandID", "");
        handleChange("Brand", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("ProductTypeID", value.ProductTypeID);
            handleChange("ProductType", value.ProductType);
            handleChange("CategoryID", value.CategoryID);
            handleChange("Category", value.Category);
            handleChange("BrandID", value.BrandID);
            handleChange("Brand", value.Brand);
            handleChange("CheckBrand", "");
        });
        setCategoryData(CategoryList);
    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂໝວດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ໜວດສິນຄ້າ</span>
                                <input className={`input form-control ${errors.Brand && 'is-invalid'}`} type="text" name="Brand" onChange={(e) => handleInputStatusChange(e)} value={values.Brand || ''} required />
                                {errors.Brand && (
                                    <p className="invalid-feedback">{errors.Brand}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດສິນຄ້າ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    { ProductTypeList && ProductTypeList.map((x, y) => {
                                        if (x.ProductType == values.ProductType) {
                                            return (
                                                <option key={x.ProductTypeID} value={x.ProductType} selected>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.ProductTypeID} value={x.ProductType}>
                                                    {x.ProductType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ໝວດສິນຄ້າ: </label>
                                <select name="CategoryID" onChange={(e) => handleSelectCategory(e)}
                                    className={`input form-control ${errors.CategoryID && 'is-invalid'}`}>
                                    { categoryData && categoryData.map((x, y) => {
                                        if (x.Category == values.Category ) {
                                            return (
                                                <option key={x.CategoryID} value={x.Category} selected>
                                                    {x.Category}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.CategoryID} value={x.Category}>
                                                    {x.Category}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateBrand;
