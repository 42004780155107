import React, { useState, useEffect, useRef, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/InventoryReport.css';
import '../../../../css/datepicker.css';
import axios from "axios";
import { API } from "../../../../services/api";
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { UserContext } from "../../../../App";
import { useReactToPrint } from 'react-to-print';

axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let Quantity = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="InventoryReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ຈຳນວນເຄື່ອງໃນສາງ</span>
                      </div>
                      
                  </div>
                  <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="Barcode">ລະຫັດ</span>
                      <span className="LaoName">ຊື່ສິນຄ້າ</span>
                      <span className="ProductType">ປະເພດສິນຄ້າ</span>
                      <span className="Category">ໝວດສິນຄ້າ</span>
                      <span className="Brand">ຫຍີ່ຫໍ້ສິນຄ້າ</span>
                      <span className="Quantity">ຈຳນວນ</span>
                  </div>
                  <div className="HeadLine">
                      <span>====================================================================</span>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            Quantity =  Number(x.Quantity) +  Number(Quantity);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="Barcode">{x.Barcode}</span>
                                            <span className="LaoName">{x.LaoName}</span>
                                            <span className="ProductType">{x.ProductType}</span>
                                            <span className="Category">{x.Category}</span>
                                            <span className="Brand">{x.Brand}</span>
                                            <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                      <span>====================================================================</span>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="Quantity">{currencyFormat(Quantity)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function InventoryReport() {

  const {LocationID, LocationName} = useContext(UserContext);
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function getPendingBill() {
    setShowData([]);
    await axios.get(`/getAllInventory`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPendingBill();
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານການຂາຍ</span>
          </div>
          <div className="txtSearch">
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ລະຫັດ</th>
                <th>ຊື່ສິນຄ້າ</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ໝວດສິນຄ້າ</th>
                <th>ຫຍີ່ຫໍ້ສິນຄ້າ</th>
                <th>ຈຳນວນ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.LaoName}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >{x.Brand}</td>
                        <td >{x.Quantity}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"LocationName": LocationName}} 
            />
        </div>
      </div>
  );
};

export default InventoryReport;
