import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { API } from "../../../services/api";
import Alert from "../../../components/alert";
import PosOrder from "./create/posorder";
import Payment from "./create/payment";
import '../../../css/RegisterTable.css';
import { AlertContext, UserContext } from "../../../App";

axios.defaults.baseURL = API;

const POSContent = createContext();


function POS() {

const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);

  const [QueueNO, setQueueNO] = useState(1);
  const [queueData, setQueueData] = useState([]);
  const [subTotal, setSubTotal] = useState([]);
  const [NetTotal, setNetTotal] = useState([]);
  const [Tax, setTax] = useState(0);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [DiscountList, setDiscountList] = useState([]);
  const [TempOrder, setTempOrder] = useState([]);


  const [showOrder, setShowOrder] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

    const handelclickRegisterTable = (QueueNO) => {
        if(QueueNO !=""){
            let q = QueueNO;
            setQueueNO(q);
            setShowOrder(true);
        }
    };

    async function getQueue() {
        axios
        .get("/getQueue")
        .then((response) => {
            let Data = response?.data;
            if(Data.length > 0)
                setQueueData(Data);
            else
                CreateQueue();
        })
        .catch((error) => {
            console.log(error);
        });
    }

    async function CreateQueue () {
        let token = localStorage.getItem("token");
        var data = {
            QueueNO: QueueNO
        };
        await axios.post("/createQueue", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getQueue();
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getQueue();
        let paymentData = localStorage.getItem("paymentTypeData");
            if (paymentData) {
                let payment = JSON.parse(paymentData);
                setPaymentTypeList(payment);
            }
            let currencyData = localStorage.getItem("currencyData");
            if (currencyData) {
                let currency = JSON.parse(currencyData);
                setCurrencyList(currency);
            }
            let discountData = localStorage.getItem("discountData");
            if (discountData) {
                let discount = JSON.parse(discountData);
                setDiscountList(discount);
            }
    }, []);

  return (
    <POSContent.Provider value={{QueueNO, setQueueData, PaymentTypeList, TempOrder, setTempOrder, CurrencyList, DiscountList, 
        showPayment, setShowPayment, showOrder, setShowOrder, subTotal, setSubTotal, NetTotal, setNetTotal, 
        Tax, setTax, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="tableFormContent">
        <Alert/> 
        <PosOrder show={showOrder}/>
        <Payment show={showPayment}/>
        <div className="tableContent">
            <div className="tableBody">
                <div className="tableItem">
                    {
                        queueData && queueData.map((x, y) => {
                            return (
                                <div key={y} className="item" onClick={() => handelclickRegisterTable(x.QueueNO)} >
                                    <div className="item-body">
                                        <div className="name">
                                            <p>{x.QueueNO}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
      </div>
    </POSContent.Provider>
  );
};

export {POSContent};
export default POS;
