import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateCategory from "./create/createCategory";
import UpdateCategory from "./update/updateCategory";

const CategoryContext = createContext();

function Category() {

  const [dataList, setDataList] = useState([]);
  const [ProductTypeList, setProductTypeList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { value } = event.target;
    if(value){
      var results = [];
      results = dataList.filter((data) => {
          return data.Category.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowData(results);
      else 
      setShowData(dataList);
    } else 
    setShowData(dataList);
  }

  const openUpdate = (ProductTypeID, ProductType, CategoryID, Category) => {
    var list = [];
    list.push({
      ProductTypeID: ProductTypeID,
      ProductType: ProductType,
      CategoryID: CategoryID,
      Category: Category
    });
    setData(list);
    setShowUpdate(true);
  };
  
  useEffect(() => {
    let userData = localStorage.getItem("categoryData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setDataList(userRole);
      setShowData(userRole);
    }
    let roleData = localStorage.getItem("productTypeData");
    if (roleData) {
      let userRole = JSON.parse(roleData);
      setProductTypeList(userRole);
    }
  }, []);

  return (
    <CategoryContext.Provider value={{dataList, setDataList, ProductTypeList, data}}>
      <div className="formContent">
        <CreateCategory show={show} onClick={handleClose}/>
        <UpdateCategory show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ໝວດສິນຄ້າ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ໝວດສິນຄ້າ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.Category}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.CategoryID} type="button" onClick={() => openUpdate(x.ProductTypeID, x.ProductType, x.CategoryID, x.Category)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </CategoryContext.Provider>
  );
};

export {CategoryContext};
export default Category;
