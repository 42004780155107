import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/productValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ProductContext } from "../product";

axios.defaults.baseURL = API;

function CreateProduct(props) {

    const {ProductTypeList, setProductList, setShowData, CategoryList, BrandList, SizeTypeList} = useContext(ProductContext);
    const [categoryData, setCategoryData] = useState([]);
    const [BrandData, setBrandData] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        if(name === "Barcode")
            check(value);
    };

    async function check (barcode) {
        let token = localStorage.getItem("token");
        await axios.put(`/checkBarcode/${barcode}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
            handleChange("CheckBarcode", temOrder[0].Barcode);
        } else {
            handleChange("CheckBarcode", "");
        }
        }).catch((err) => {
            handleChange("CheckBarcode", "");
            console.log(err);
        });
      }

    const handelInputNumber = (e) => {
        const { name, value } = e.target;
          const re = /^[0-9\b]+$/;
          if (value) {
              if (value === '' || re.test(value.split(',').join(''))) {
                  handleChange(name, value.split(',').join(''));
              }  
          } else {
              handleChange(name, 0);
          }
      }

    const handleSelectProductType = (e) => {
        var data = ProductTypeList.filter(function (item) {
            return item.ProductType == e.target.value
        })
        if (data.length > 0) {
            var obj = CategoryList.filter(function (item) {
                return item.ProductTypeID == data[0].ProductTypeID
            })
            setCategoryData(obj);
            handleChange("ProductType", data[0].ProductType);
            handleChange("ProductTypeID", data[0].ProductTypeID);
        } else {
            setCategoryData([]);
            setBrandData([]);
            handleChange("ProductType", "");
            handleChange("ProductTypeID", "");
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
            handleChange("BrandID", "");
        }
    };

    const handleSelectCategory = (e) => {
        var data = categoryData.filter(function (item) {
            return item.Category == e.target.value
        })
        if (data.length > 0) {
            var obj = BrandList.filter(function (item) {
                return item.CategoryID == data[0].CategoryID
            })
            setBrandData(obj);
            handleChange("Category", data[0].Category);
            handleChange("CategoryID", data[0].CategoryID);
        } else {
            setBrandData([]);
            handleChange("Category", "");
            handleChange("CategoryID", "");
            handleChange("Brand", "");
            handleChange("BrandID", "");
        }
    };

    const handleSelectBrand = (e) => {
        var data = BrandData.filter(function (item) {
            return item.Brand == e.target.value
        })
        if (data.length > 0) {
            handleChange("Brand", data[0].Brand);
            handleChange("BrandID", data[0].BrandID);
        } else {
            handleChange("Brand", "");
            handleChange("BrandID", "");
        }
    };

    const handleSelectSizeType = (e) => {
        var data = SizeTypeList.filter(function (item) {
            return item.SizeType == e.target.value
        })
        if (data.length > 0) {
            handleChange("SizeType", data[0].SizeType);
            handleChange("SizeTypeID", data[0].SizeTypeID);
        } else {
            handleChange("SizeType", "");
            handleChange("SizeTypeID", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ProductTypeID: values.ProductTypeID,
            CategoryID: values.CategoryID,
            BrandID: values.BrandID,
            SizeTypeID: values.SizeTypeID,
            Barcode: values.Barcode,
            LaoName: values.LaoName,
            EngName: values.EngName,
            BuyPrice: values.BuyPrice,
            Retail_Price: values.Retail_Price,
            Wholesale_Price: values.Wholesale_Price,
            Color: values.Color,
            Size: values.Size,
            StatusID: 1,
        }
        await axios.post("/createProduct", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllProductLimitTop10();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllProductLimitTop10() {
        axios.get("/getAllProduct").then((response) => {
          let Data = response?.data;
          setProductList(Data);
          setShowData(Data);
        }).catch((error) => {
          console.log(error);
        });
    }

    const ClearData = () => {
        handleChange("Barcode", "");
        handleChange("LaoName", "");
        handleChange("EngName", "");
        handleChange("BuyPrice", "");
        handleChange("Retail_Price", "");
        handleChange("Wholesale_Price", "");
        handleChange("Color", "");
        handleChange("Size", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>Barcode</span>
                                <input className={`input form-control ${errors.Barcode && 'is-invalid'}`} type="text" name="Barcode" onChange={(e) => handleInputChange(e)} value={values.Barcode || ''} required />
                                {errors.Barcode && (
                                    <p className="invalid-feedback">{errors.Barcode}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາລາວ</span>
                                <input className={`input form-control ${errors.LaoName && 'is-invalid'}`} type="text" name="LaoName" onChange={(e) => handleInputChange(e)} value={values.LaoName || ''} required />
                                {errors.LaoName && (
                                    <p className="invalid-feedback">{errors.LaoName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາອັງກິດ</span>
                                <input className={`input form-control ${errors.EngName && 'is-invalid'}`} type="text" name="EngName" onChange={(e) => handleInputChange(e)} value={values.EngName || ''} required />
                                {errors.EngName && (
                                    <p className="invalid-feedback">{errors.EngName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>ລາຄາຊື້</span>
                                <input className={`input form-control ${errors.BuyPrice && 'is-invalid'}`} type="text" name="BuyPrice" onChange={(e) => handelInputNumber(e)} value={values.BuyPrice || ''} required />
                                {errors.BuyPrice && (
                                    <p className="invalid-feedback">{errors.BuyPrice}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາຂາຍ</span>
                                <input className={`input form-control ${errors.Retail_Price && 'is-invalid'}`} type="text" name="Retail_Price" onChange={(e) => handelInputNumber(e)} value={values.Retail_Price || ''} required />
                                {errors.Retail_Price && (
                                    <p className="invalid-feedback">{errors.Retail_Price}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາຂາຍສົ່ງ</span>
                                <input className={`input form-control ${errors.Wholesale_Price && 'is-invalid'}`} type="text" name="Wholesale_Price" onChange={(e) => handelInputNumber(e)} value={values.Wholesale_Price || ''} required />
                                {errors.Wholesale_Price && (
                                    <p className="invalid-feedback">{errors.Wholesale_Price}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ປະເພດສິນຄ້າ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດສິນຄ້າ'}
                                    </option>
                                    {   ProductTypeList && ProductTypeList.map(item => (
                                        <option
                                            key={item.ProductTypeID}
                                            value={item.ProductType}
                                        >
                                            {item.ProductType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ໜວດສິນຄ້າ: </label>
                                <select name="CategoryID" onChange={(e) => handleSelectCategory(e)}
                                    className={`input form-control ${errors.CategoryID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດ'}
                                    </option>
                                    {   categoryData && categoryData.map(item => (
                                        <option
                                            key={item.CategoryID}
                                            value={item.Category}
                                        >
                                            {item.Category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ຫຍີ່ຫໍ້: </label>
                                <select name="BrandID" onChange={(e) => handleSelectBrand(e)}
                                    className={`input form-control ${errors.BrandID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກຫຍີ່ຫໍ້'}
                                    </option>
                                    {   BrandData && BrandData.map(item => (
                                        <option
                                            key={item.BrandID}
                                            value={item.Brand}
                                        >
                                            {item.Brand}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ປະເພດຂະໜາດ: </label>
                                <select name="SizeTypeID" onChange={(e) => handleSelectSizeType(e)}
                                    className={`input form-control ${errors.SizeTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດຂະໜາດ'}
                                    </option>
                                    {   SizeTypeList && SizeTypeList.map(item => (
                                        <option
                                            key={item.SizeTypeID}
                                            value={item.SizeType}
                                        >
                                            {item.SizeType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <span>ຂະໜາດ</span>
                                <input className={`input form-control ${errors.Size && 'is-invalid'}`} type="text" name="Size" onChange={(e) => handleInputChange(e)} value={values.Size || ''} required />
                                {errors.Size && (
                                    <p className="invalid-feedback">{errors.Size}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ສີ</span>
                                <input className={`input form-control ${errors.Color && 'is-invalid'}`} type="text" name="Color" onChange={(e) => handleInputChange(e)} value={values.Color || ''} required />
                                {errors.Color && (
                                    <p className="invalid-feedback">{errors.Color}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateProduct;
