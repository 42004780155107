import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;

function CancelBill() {

  const [showData, setShowData] = useState([]);

  async function HandleCancelBill (TransactionID) {
    let token = localStorage.getItem("token");
    await axios.put(`/cancelBill/${TransactionID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getTransactionDetail(TransactionID); 
      ClearData();
    }).catch((err) => {
        console.log(err);
    });
  }

  async function getTransactionDetail (TransactionID) {
    await axios.get(`/getTransactionDetail/${TransactionID}`).then((response) => {
      let Data = response?.data;
      if(Data.length > 0){
        for (var i=0; i < Data.length; i++) {
          getStoreInventory(Data[i].ProductID, Data[i].Quantity);
        } 
      } 
    }).catch((error) => {
      console.log(error);
    });
  }


  async function getStoreInventory (ProductID, Quantity) {
    let token = localStorage.getItem("token");
    await axios.get(`/getInventory/${ProductID}`).then((response) => {
        let inventoryData = response?.data;
        var gQuantity = 0;
        if(inventoryData.length > 0){
            gQuantity = Number(inventoryData[0].Quantity) - Number(Quantity);
            dropInventory(inventoryData[0].InventoryID, gQuantity);
        } 
    })
      .catch((error) => {
        console.log(error);
    });

}

async function dropInventory(InventoryID, qty) {
    let token = localStorage.getItem("token");
    var data = {
        Quantity: qty
    }
    await axios.put(`/updateInventory/${InventoryID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
}

  const ClearData = () => {
    setShowData([]);
  }

  async function SearchTransaction(e) {
    const { value } = e.target;
    if(value){
      await axios.get(`/SearchTransaction/${value}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowData([]);
    }
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ຍົກເລີກບິນ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາເລກບິນ" onChange={(e) => SearchTransaction(e)} />
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດລວມ</th>
                <th>ສ່ວນຫລຸດ</th>
                <th>ຍອດທີ່ຕ້ອງຈ່າຍ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ຈ່າຍເພີ່ມ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TransactionID}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.Discount}</td>
                        <td >{x.NetTotal}</td>
                        <td >{x.PaidAmount}</td>
                        <td >{x.PaidMoreAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => HandleCancelBill(x.TransactionID,)}>ຍົກເລີກບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default CancelBill;
