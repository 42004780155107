import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import TemOrderByBarcode from "./create/tempOrderByBarcode";
import TemOrderByName from "./create/tempOrderByName";
import CreatePurchaseOrder from "./create/createPurchaseOrder";

const PurchaseOrderContext = createContext();

function PurchaseOrder() {

  const [dataList, setDataList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [ShowByName, setShowByName] = useState(false);
  const [SupplierList, setSupplierList] = useState([]);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const handleCloseCreate = () => setShowCreate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowByName = () => setShowByName(true);
  const handleCloseByName = () => setShowByName(false);

  function currencyFormat(num) {
    if (num !== "") {
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  const minusOrder = (Barcode) => {
    for (var i=0; i < showData.length; i++) {
      if (showData[i].Barcode == Barcode) {
          if(Number(showData[i].Quantity) - 1 > 0)
          {
            showData[i].Quantity = Number(showData[i].Quantity) - 1;
            showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
            localStorage.setItem("purchaseOrderData", JSON.stringify(showData));
          } else {
            showData.splice(i, 1);
            localStorage.setItem("purchaseOrderData", JSON.stringify(showData));
          }
          break;
      }
    }  
    setOrderList();
  }

  const addOrder = (Barcode) => {
    for (var i=0; i < showData.length; i++) {
      if (showData[i].Barcode == Barcode) {
        showData[i].Quantity = Number(showData[i].Quantity) + Number(1);
        showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
        localStorage.setItem("purchaseOrderData", JSON.stringify(showData));
        break;
      }
    }  
    setOrderList();
  }

  const setOrderList = () => {
    let userData = localStorage.getItem("purchaseOrderData");
    if (userData) {
        let userRole = JSON.parse(userData);
        setShowData(userRole);
    } else
        setShowData([]);
  }

  const handleCreate = () => {
    if(showData.length > 0){
      setShowCreate(true);
    }
  }  
  
  useEffect(() => {
    let userData = localStorage.getItem("productData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setDataList(userRole);
    }

    let purchaseOrderData = localStorage.getItem("purchaseOrderData");
    if (purchaseOrderData) {
      let userRole = JSON.parse(purchaseOrderData);
      setShowData(userRole);
    }

    let supplierData = localStorage.getItem("supplierData");
    if (supplierData) {
      let userRole = JSON.parse(supplierData);
      setSupplierList(userRole);
    }

    let paymentData = localStorage.getItem("paymentTypeData");
    if (paymentData) {
      let userRole = JSON.parse(paymentData);
      setPaymentTypeList(userRole);
    }
  }, []);

  return (
    <PurchaseOrderContext.Provider value={{dataList, setDataList, showData, setShowData, SupplierList, PaymentTypeList}}>
      <div className="formContent">
        <TemOrderByBarcode show={show} onClick={handleClose}/>
        <TemOrderByName show={ShowByName} onClick={handleCloseByName}/>
        <CreatePurchaseOrder show={showCreate} onClick={handleCloseCreate}/>
        <div className="Header">
          <div className="title">
              <span>ສ້າງໃບສັ່ງຊື້</span>
          </div>
          <div className="txtSearch">
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມດ້ວຍລະຫັດ</button>
            <button className="btn btn-secondary" onClick={handleShowByName} >ເພີ່ມດ້ວຍຊື່</button>
            <button className="btn btn-success" onClick={handleCreate} >ສ້າງໃບສັ່ງຊື້</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>Barcode</th>
                <th>ປະເພດສິນຄ້າ</th>
                <th>ຊື່ພາສາລາວ</th>
                <th>ຊື່ພາສາອັງກິດ</th>
                <th>ລາຄາຊື້</th>
                <th>ຈຳນວນ</th>
                <th>ລວມ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.Barcode}</td>
                        <td >{x.ProductType}</td>
                        <td >{x.LaoName}</td>
                        <td >{x.EngName}</td>
                        <td >{currencyFormat(x.BuyPrice)}</td>
                        <td >{currencyFormat(x.Quantity)}</td>
                        <td >{currencyFormat(x.SubTotal)}</td>
                        <td >
                          <button className="btn btn-primary" type="button" onClick={() => addOrder(x.Barcode)}>+</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-danger" type="button" onClick={() => minusOrder(x.Barcode)}>-</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </PurchaseOrderContext.Provider>
  );
};

export {PurchaseOrderContext};
export default PurchaseOrder;
