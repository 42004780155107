function loginValidate(values) {
    let errors = {};
    if (!values.username)
        errors.username = 'username is required';

    if (!values.password) 
        errors.password = 'Password is required';
    else if (values.password.length < 6) 
        errors.password = 'Password must be 6 or more characters';

    return errors;
};

export default loginValidate;