import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/employeeValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { DataContext } from "../employee";

axios.defaults.baseURL = API;

function CreateEmployee(props) {

    const {dataList, setDataList, userID, LocationList, RoleList } = useContext(DataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handleInputNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.username.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckUserName", results[0].username);
        else
            handleChange("CheckUserName", "");
    }

    const handleSelectLocation = (e) => {
        var data = LocationList.filter(function (item) {
            return item.LocationName == e.target.value
        })
        if (data.length > 0) {
            handleChange("LocationName", data[0].LocationName);
            handleChange("LocationID", data[0].LocationID);
        } else {
            handleChange("LocationName", "");
            handleChange("LocationID", "");
        }
    };

    const handleSelectRole = (e) => {
        var data = RoleList.filter(function (item) {
            return item.Role == e.target.value
        })
        if (data.length > 0) {
            handleChange("Role", data[0].Role);
            handleChange("RoleID", data[0].RoleID);
        } else {
            handleChange("RoleID", "");
            handleChange("Role", "");
        }
    };
    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            name: values.Name,
            surname: values.SurName,
            Tell: values.Tell,
            username: values.UserName,
            password: values.Password,
            locationID: values.LocationID,
            roleID: values.RoleID,
            status: 1,
            create_by: userID,
        }
        await axios.post("/createUser", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                EmployeeID: response?.data?.insertId,
                name: values.Name,
                surname: values.SurName,
                Tell: values.Tell,
                username: values.UserName,
                password: values.Password,
                locationID: values.LocationID,
                LocationName: values.LocationName,
                roleID: values.RoleID,
                Role: values.Role,
                status: 1,
                create_by: userID,
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("userData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("Name", "");
        handleChange("SurName", "");
        handleChange("UserName", "");
        handleChange("Tell", "");
        handleChange("Password", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຂໍ້ມູນພະນັກງານ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື້</span>
                                <input className={`input form-control ${errors.Name && 'is-invalid'}`} type="text" name="Name" onChange={(e) => handleInputChange(e)} value={values.Name || ''} required />
                                {errors.Name && (
                                    <p className="invalid-feedback">{errors.Name}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ນາມສະກູນ</span>
                                <input className={`input form-control ${errors.SurName && 'is-invalid'}`} type="text" name="SurName" onChange={(e) => handleInputChange(e)} value={values.SurName || ''} required />
                                {errors.SurName && (
                                    <p className="invalid-feedback">{errors.SurName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>UserName:</span>
                                <input className={`input form-control ${errors.UserName && 'is-invalid'}`} type="text" name="UserName" onChange={(e) => handleInputNameChange(e)} value={values.UserName || ''} required />
                                {errors.UserName && (
                                    <p className="invalid-feedback">{errors.UserName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Password:</span>
                                <input className={`input form-control ${errors.Password && 'is-invalid'}`} type="text" name="Password" onChange={(e) => handleInputChange(e)} value={values.Password || ''} required />
                                {errors.Password && (
                                    <p className="invalid-feedback">{errors.Password}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ສາຂາ: </label>
                                <select name="LocationID" onChange={(e) => handleSelectLocation(e)}
                                    className={`input form-control ${errors.LocationID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກສາຂາ'}
                                    </option>,
                                    {   LocationList && LocationList.map(item => (
                                        <option
                                            key={item.LocationID}
                                            value={item.LocationName}
                                        >
                                            {item.LocationName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ສິດນຳໃຊ້: </label>
                                <select name="RoleID" onChange={(e) => handleSelectRole(e)}
                                    className={`input form-control ${errors.RoleID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກສິດນຳໃຊ້'}
                                    </option>,
                                    {   RoleList && RoleList.map(item => (
                                        <option
                                            key={item.RoleID}
                                            value={item.Role}
                                        >
                                            {item.Role}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ເບີໂທ:</span>
                                <input className={`input form-control ${errors.Tell && 'is-invalid'}`} type="text" name="Tell" onChange={(e) => handleInputChange(e)} value={values.Tell || ''} required />
                                {errors.Tell && (
                                    <p className="invalid-feedback">{errors.Tell}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateEmployee;
