import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/member/rewardcardValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { RewardCardDataContext } from "../rewardCard";

axios.defaults.baseURL = API;

function CreateRewardCard(props) {

    const {dataList, setDataList, userID } = useContext(RewardCardDataContext);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
    };

    const handleInputNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.RewardCardNO.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckRewardCardNO", results[0].RewardCardNO);
        else
            handleChange("CheckRewardCardNO", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            RewardCardNO: values.RewardCardNO,
            TEL: values.TEL,
            Email: values.Email,
            Point: 0,
            Identification: values.Identification,
            StatusID: 1,
            CreateByID: userID,
            CreateDate: new Date(),
        }
        await axios.post("/createRewardCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                RewardCardID: response?.data?.insertId,
                FirstName: values.FirstName,
                LastName: values.LastName,
                RewardCardNO: values.RewardCardNO,
                TEL: values.TEL,
                Email: values.Email,
                Point: 0,
                Identification: values.Identification,
                StatusID: 1,
                CreateByID: userID,
                CreateDate: new Date(),
            }
            dataList.push(obj);
            setDataList(dataList);
            localStorage.setItem("rewardcardData", JSON.stringify(dataList));
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const ClearData = () => {
        handleChange("FirstName", "");
        handleChange("LastName", "");
        handleChange("RewardCardNO", "");
        handleChange("TEL", "");
        handleChange("Email", "");
        handleChange("Identification", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຂໍ້ມູນບັດ Member</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື້</span>
                                <input className={`input form-control ${errors.FirstName && 'is-invalid'}`} type="text" name="FirstName" onChange={(e) => handleInputChange(e)} value={values.FirstName || ''} required />
                                {errors.FirstName && (
                                    <p className="invalid-feedback">{errors.FirstName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ນາມສະກູນ</span>
                                <input className={`input form-control ${errors.LastName && 'is-invalid'}`} type="text" name="LastName" onChange={(e) => handleInputChange(e)} value={values.LastName || ''} required />
                                {errors.LastName && (
                                    <p className="invalid-feedback">{errors.LastName}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>RewardCardNO:</span>
                                <input className={`input form-control ${errors.RewardCardNO && 'is-invalid'}`} type="text" name="RewardCardNO" onChange={(e) => handleInputNameChange(e)} value={values.RewardCardNO || ''} required />
                                {errors.RewardCardNO && (
                                    <p className="invalid-feedback">{errors.RewardCardNO}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Email:</span>
                                <input className={`input form-control ${errors.Email && 'is-invalid'}`} type="text" name="Email" onChange={(e) => handleInputChange(e)} value={values.Email || ''} required />
                                {errors.Email && (
                                    <p className="invalid-feedback">{errors.Email}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col form-group">
                                <span>ເບີໂທ:</span>
                                <input className={`input form-control ${errors.TEL && 'is-invalid'}`} type="text" name="TEL" onChange={(e) => handleInputChange(e)} value={values.TEL || ''} required />
                                {errors.TEL && (
                                    <p className="invalid-feedback">{errors.TEL}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Identification:</span>
                                <input className={`input form-control ${errors.Identification && 'is-invalid'}`} type="text" name="Identification" onChange={(e) => handleInputChange(e)} value={values.Identification || ''} required />
                                {errors.Identification && (
                                    <p className="invalid-feedback">{errors.Identification}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateRewardCard;
