import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import ReceivedBillDetail from "./update/ReceivedBillDetail";

axios.defaults.baseURL = API;

const ReceivedBillContent = createContext();

function ReceivedBill() {
  const {LocationID} = useContext(UserContext);
  const [dataList, setDataList] = useState([]);
  const [PurchaseOrderDetails, setPurchaseOrderDetail] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getPendingBill() {
    var StatusID = 6;
    await axios.get(`/getPurchaseOrderBill/${LocationID}/${StatusID}`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function openProductDetail(PurchaseOrderID) {
    axios
      .get(`/getPurchaseOrderProduct/${PurchaseOrderID}`)
      .then((response) => {
        let Data = response?.data;
        setPurchaseOrderDetail(Data);
        handleShow();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getPendingBill();
  }, []);

  return (
    <ReceivedBillContent.Provider value={{PurchaseOrderDetails}}>
      <div className="formContent">
        <ReceivedBillDetail show={show} onClick={handleClose}/>
        <div className="Header">
          <div className="title">
              <span>ສ້າງໃບສັ່ງຊື້</span>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຜູ້ສະໜອງ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList && dataList.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.PurchaseOrderID}</td>
                        <td >{x.SupplierName}</td>
                        <td >{x.PaymentType}</td>
                        <td >{x.CreatedDate}</td>
                        <td >
                          <button className="btn btn-warning" type="button" onClick={() => openProductDetail(x.PurchaseOrderID)}>ລາຍລະອຽດ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ReceivedBillContent.Provider>
  );
};

export {ReceivedBillContent};
export default ReceivedBill;
