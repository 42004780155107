import React, { useState, createContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './css/admin.css';
import { API } from "./services/api";
import axios from "axios";
import logo from './img/logo/ManeeCafeLogo.jpg';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// route v6 replace  switch to Routes

// import components
// import NavbarComponent from './components/navbar';

// import pages

import Login from './pages/Login';

// import admin page

import Employee from './pages/admin/basicInfo/employee';
import PaymentType from './pages/admin/basicInfo/paymentType';
import Currency from './pages/admin/basicInfo/currency';
import Location from './pages/admin/basicInfo/location';
import Role from './pages/admin/basicInfo/role';
import SizeType from './pages/admin/basicInfo/sizeType';
import Status from './pages/admin/basicInfo/status';
import TableNumber from './pages/admin/basicInfo/tableNumber';
import Supplier from './pages/admin/basicInfo/supplier';

// import product 
import ProductType from './pages/admin/product/productType';
import Category from './pages/admin/product/category';
import Brand from './pages/admin/product/brand';
import Product from './pages/admin/product/product';
import Menu from './pages/admin/product/menu';
import SubMenu from './pages/admin/product/subMenu';
import UploadImage from './pages/admin/product/uploadImgProduct';

// import Stock
import PurchaseOrder from './pages/admin/stock/purchaseOrder';
import PendingBill from './pages/admin/stock/pendingBill';
import OrderBill from './pages/admin/stock/orderBill';
import ReceivedBill from './pages/admin/stock/receivedBill';
import InventoryReport from './pages/admin/stock/report/InventoryReport';

// Import Member
import Member from './pages/admin/member/member';

// Import Member
import RewardCard from './pages/admin/member/rewardCard';
import TopUp from './pages/admin/member/topup';

//Import GiftCard 
import GiftCard from './pages/admin/member/giftCard';
import SellGiftCard from './pages/admin/member/sellgiftcard';


// import POS
import POS from './pages/admin/pos/pos';
import CancelBill from './pages/admin/pos/cancelBill';
import TransactionReport from './pages/admin/pos/report/TransactionReport';
import CashReport from './pages/admin/pos/report/CashReport';
import TaxReport from './pages/admin/pos/report/TaxReport';
// import RegisterTable from './pages/admin/pos/registertable';

// import customer page
import Customer_home from './pages/customer/customer_home';

const UserContext = createContext();
const AlertContext = createContext();

function App() {

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, setLogin] = useState(false);
  const [userID, setUserID] = useState();
  const [userName, setUserName] = useState();
  const [LocationName, setLocationName] = useState();
  const [role, setRole] = useState();
  const [LocationID, setLocationID] = useState();
  const [SubMenuList, setSubMenuList] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let userData = localStorage.getItem("data");
    if (token) {
      let data = { token: `MR_ADMIN ${token}` };
      axios
        .post(`${API}/verify-token`, data)
        .then((response) => {
          if (response?.status === 200) {
            setLogin(true);
            let userRole = JSON.parse(userData);
            setUserID(userRole.id);
            setUserName(userRole.name);
            setLocationName(userRole.LocationName);
            setRole(userRole.role);
            setLocationID(userRole.locationID)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (!login) {
    return <Login setLogin={setLogin} setUserID={setUserID} setLocationID={setLocationID} setLocationName={setLocationName} setUserName={setUserName} setRole={setRole} />;
  } 

  const navbarMenuList = ["ຂໍ້ມູນພື້ນຖ້ານ", "ຂໍ້ມູນສິນຄ້າ", "ຂໍ້ມູນສາງ", "POS","SignOut"];
  const sideBardMenuList = [
    { location: 'ສາຂາ', roles: 'ສິດນຳໃຊ້', status: 'ສະຖານະ', supplier: 'ຜູ້ສະໜອງ', employee: 'ພະນັກງານ', paymentType: 'ປະເພດຈ່າຍ', sizeType: 'ປະເພດຂະໜາດ', TableNo: 'ສ້າງເລກໂຕະ'},
    { ProductType: "ປະເພດສິນຄ້າ", Category: "ໝວດສິນຄ້າ", Brand: "ຫຍີ່ຫໍ້ສິນຄ້າ", Menu: "ສ້າງເມນູຂາຍ", SubMenu: "ສ້າງເມນູຂາຍຍ່ອຍ", Product: "ສ້າງຂໍ້ມູນສິນຄ້າ"},
    { PurchaseOrder: 'ສ້າງໃບສັ່ງຊື້ເຄື່ອງ', PendingBill: 'ບິນລໍຖ້າສັ່ງຊື້', OrderBill: 'ບິນທີ່ສັ່ງຊື້ແລ້ວ', ReceivedBill: 'ບິນທີ່ຮັບເຄື່ອງເຂົ້າສາງ', InventoryReport: 'ລາຍງານຈຳນວນສິນຄ້າ'},
    // { Member: 'ສ້າງ Member',   RewardCard: 'ສ້າງ Reward Card', TopUp: 'ເຕີມບັດ Member', TopUpReport: 'ລາຍງານເຕີມບັດ', SaleGiftCardReport: 'ລາຍງານຂາຍບັດ Gift Card' },
    { POS: 'ຂາຍເຄື່ອງ',   CancelBill: 'ຍົກເລີກບິນ', TransactionReport: 'ລາຍງານບິນຂາຍ', CashReport: 'ລາຍງານສະຫລຸບເງິນ', TaxReport:'ລາຍງານອາກອນ' },
  ];

  const handleClick = (e) => {
    console.log(e)
    setSubMenuList(sideBardMenuList[e]);
    if(e === 4)
    {
      CreateQueue();
      signout();
    }
  }

  async function CreateQueue () {
    let token = localStorage.getItem("token");
    await axios.post("/deleteQueue", { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
}

  const signout = () => {
     localStorage.removeItem('data');
     localStorage.removeItem('userData');
     localStorage.removeItem('token');
     localStorage.removeItem('productData');
     localStorage.removeItem('paymentTypeData');
     localStorage.removeItem('productTypeData');
     localStorage.removeItem('categoryData');
     localStorage.removeItem('currencyData');
     localStorage.removeItem('brandData');
     localStorage.removeItem('sizeTypeData');
     localStorage.removeItem('supplierData');
     localStorage.removeItem('locationData');
     localStorage.removeItem('statusData');
     localStorage.removeItem('ProductTypeData');
     localStorage.removeItem('purchaseOrderData');
     localStorage.removeItem('roleData');
     localStorage.removeItem('tableNumberData');
     localStorage.removeItem('memberData');
     localStorage.removeItem('discountData');
     localStorage.removeItem('menuData');
     localStorage.removeItem('productBySubMenuData');
     localStorage.removeItem('subMenuData');
     setLogin(false);
  };

  if(role == "Admin" || role =="Cashier"){
    return (
      <AlertContext.Provider value={{showAlert, setShowAlert, errorMessage, setErrorMessage}}>
        <UserContext.Provider value={{ userName, userID, LocationID, LocationName, login, setShowAlert, setErrorMessage}}>
          <div className="admin_container">
              <div className="navbarContent">
                {
                  navbarMenuList && navbarMenuList.map((value, key) =>
                    {
                      return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                        {value}
                      </div>)
                    }
                  )
                }
              </div>
              <div className="sidebarContent">
                <div className="imglogo">
                  <img className="logo" src={logo} />
                </div>
                <div className="sideBarBody">
                  {
                    SubMenuList && Object.keys(SubMenuList).map((x, key) =>
                      {
                        return (
                          <Link key={key} className="link" to={{ pathname: "/" + x }} >
                            <div className="sideBarItem" tabIndex={key}>
                              <div className="submenutext">
                                {SubMenuList[x]}
                              </div>
                            </div>
                          </Link>
                        )
                      }
                    )
                  }
                </div>
              </div>
              <div className="appContent">
                  <Routes>
                      <Route exact path='/employee' element={<Employee/>}/>
                      <Route exact path='/paymentType' element={<PaymentType/>}/>
                      <Route exact path='/Currency' element={<Currency/>}/>
                      <Route exact path='/location' element={<Location/>}/>
                      <Route exact path='/roles' element={<Role/>}/>
                      <Route exact path='/sizeType' element={<SizeType/>}/>
                      <Route exact path='/status' element={<Status/>}/>
                      <Route exact path='/TableNo' element={<TableNumber/>}/>
                      <Route exact path='/supplier' element={<Supplier/>}/>

                      <Route exact path='/ProductType' element={<ProductType/>}/>
                      <Route exact path='/Category' element={<Category/>}/>
                      <Route exact path='/Brand' element={<Brand/>}/>
                      <Route exact path='/Menu' element={<Menu/>}/>
                      <Route exact path='/SubMenu' element={<SubMenu/>}/>
                      <Route exact path='/Product' element={<Product/>}/>
                      <Route exact path='/UploadImage' element={<UploadImage/>}/>

                      <Route exact path='/PurchaseOrder' element={<PurchaseOrder/>}/>
                      <Route exact path='/PendingBill' element={<PendingBill/>}/>
                      <Route exact path='/OrderBill' element={<OrderBill/>}/>
                      <Route exact path='/ReceivedBill' element={<ReceivedBill/>}/>
                      <Route exact path='/InventoryReport' element={<InventoryReport/>}/>

                      <Route exact path='/Member' element={<Member/>}/>
                      <Route exact path='/TopUp' element={<TopUp/>}/>
                      <Route exact path='/RewardCard' element={<RewardCard/>}/>
                      <Route exact path='/GiftCard' element={<GiftCard/>}/>
                      <Route exact path='/SellGiftCard' element={<SellGiftCard/>}/>

                      <Route exact path='/POS' element={<POS/>}/>
                      <Route exact path='/CancelBill' element={<CancelBill/>}/>
                      <Route exact path='/TransactionReport' element={<TransactionReport/>}/>
                      <Route exact path='/CashReport' element={<CashReport/>}/>
                      <Route exact path='/TaxReport' element={<TaxReport/>}/>
                  </Routes> 
              </div>
          </div>
        </UserContext.Provider>
      </AlertContext.Provider>
    );
  } else {
    return (
      <div>
        <UserContext.Provider value={{ userName, userID, login}}>
          <Customer_home />
        </UserContext.Provider>
      </div>
        
    );
  }
}

export {AlertContext, UserContext};
export default App;
