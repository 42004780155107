import React from "react";
import useForm from "../services/UseForm";
import validate from '../validations/admin/loginValidate';
import axios from "axios";
import { API } from "../services/api";
import logo from '../img/logo/ManeeCafeLogo.jpg';
import '../css/login.css';


axios.defaults.baseURL = API;

export default function Login({setLogin, setUserID, setLocationID, setLocationName, setUserName, setRole}) {
  

  const handleInputChange = event => {
    const { name, value } = event.target;
    handleChange(name, value)
  };

  const handleLogin = () => {
    let data = { username: values.username, password: values.password };
    axios
      .post("/login-user", data).then((response) => {
        let userData = response?.data?.data;
        let token = response?.data?.token;
        let userDataString = JSON.stringify(userData);
        localStorage.setItem("data", userDataString);
        localStorage.setItem("token", token);
        setUserID(userData.id);
        setLocationID(userData.locationID);
        setLocationName(userData.LocationName);
        setUserName(userData.name);
        setLogin(true);
        setRole(userData.role);
        // getAllProductLimitTop10();
        getAllPaymentType();
        getAllProductType();
        getAllCategory();
        getAllBrand();
        getAllSizeType();
        getAllSupplier();
        getAllLocation();
        getAllStatus();
        getAllUser();
        getAllRole();
        getMenu();
        getsubmenu();
        getProductBySubmenu();
        getAllDiscount();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  async function getAllPaymentType() {
    axios
      .get("/getAllPaymentType")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("paymentTypeData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllProductType() {
    axios
      .get("/getAllProductType/")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("productTypeData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllCategory() {
    axios
      .get("/getAllCategory")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("categoryData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllBrand() {
    axios
      .get("/getAllBrand")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("brandData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllSizeType() {
    axios
      .get("/getAllSizeType")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("sizeTypeData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllSupplier() {
    axios
      .get("/getAllSupplier")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("supplierData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllLocation() {
    axios
      .get("/getAllLocation")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("locationData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllStatus() {
    axios
      .get("/getAllStatus")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("statusData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllUser() {
    axios
      .get("/getUsers")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("userData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllRole() {
    axios
      .get("/getAllRole")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("roleData", DataString);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getMenu() {
    axios
      .get("/getmenu")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("menuData", DataString);
      })
      .catch((error) => {
        console.log(error);
    });
  }

  async function getsubmenu() {
    axios
      .get("/getsubmenu")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("subMenuData", DataString);
      })
      .catch((error) => {
        console.log(error);
    });
  }

  async function getProductBySubmenu() {
    axios
      .get("/getProductBySubmenu")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("productBySubMenuData", DataString);
      })
      .catch((error) => {
        console.log(error);
    });
  }

  async function getAllDiscount() {
    axios
      .get("/getAllDiscount")
      .then((response) => {
        let Data = response?.data;
        let DataString = JSON.stringify(Data);
        localStorage.setItem("discountData", DataString);
      })
      .catch((error) => {
        console.log(error);
    });
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(handleLogin, validate);

  return (
    <div className="LoginContainer">
      <div className="LoginBody">
        <div className="LoginLeft">
          <img className="logo" src={logo} />
        </div>
        <div className="LoginRight">
          <form onSubmit={handleSubmit} noValidate>
            <div className="userName">
              <label className="label-title">UserName</label>
              <input autoComplete="off" className={`input form-control ${errors.username && 'is-invalid'}`} name="username" onChange={(e) => handleInputChange(e)} value={values.username || ''} required />
              {errors.username && (<p className="invalid-feedback">{errors.username}</p>)}
            </div>
            <div className="Password">
              <label className="label-title">Password</label>
              <input className={`input form-control ${errors.password && 'is-invalid'}`} type="password" name="password" onChange={(e) => handleInputChange(e)} value={values.password || ''} required />
              {errors.password && (
                  <p className="invalid-feedback">{errors.password}</p>
              )}
            </div>
            <div className="button">
              <input type="submit" value="Login" className="btn btn-warning"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

