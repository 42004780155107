import React, { useState, useEffect, useContext, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateEmployee from "./create/createEmployee";
import UpdateEmployee from "./update/updateEmployee";
import { UserContext } from "../../../App";

const DataContext = createContext();

function Employee() {

  const {userID} = useContext(UserContext);

  const [dataList, setDataList] = useState([]);
  const [ShowData, setShowData] = useState([]);
  const [Data, setData] = useState([]);
  const [LocationList, setLocationList] = useState([]);
  const [RoleList, setRoleList] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = dataList.filter((data) => {
          return data.username.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
        setShowData(results);
      else 
        setShowData(dataList);
    } else 
      setShowData(dataList);
  }

  const openUpdate = (EmployeeID, name, surname, username, Tell, LocationID, LocationName, RoleID, Role) => {
    var list = [];
    list.push({
      EmployeeID: EmployeeID,
      Name: name,
      SurName: surname,
      UserName: username,
      Tell: Tell,
      LocationID: LocationID,
      LocationName: LocationName,
      RoleID: RoleID,
      Role: Role,
    });
    setData(list);
    setShowUpdate(true);
  };
  
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setDataList(userRole);
      setShowData(userRole);
    }
    let locationData = localStorage.getItem("locationData");
    if (locationData) {
      let userRole = JSON.parse(locationData);
      setLocationList(userRole);
    }
    let roleData = localStorage.getItem("roleData");
    if (roleData) {
      console.log(roleData)
      let userRole = JSON.parse(roleData);
      setRoleList(userRole);
    }
  }, []);

  return (
    <DataContext.Provider value={{dataList, setDataList, userID, Data, LocationList, RoleList, setData}}>
      <div className="formContent">
        <CreateEmployee show={show} onClick={handleClose}/>
        <UpdateEmployee show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ສາຂາ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ຊື່</th>
                <th>ນາມສະກູນ</th>
                <th>ເບີໂທ</th>
                <th>ສາຂາ</th>
                <th>ໜ້າທີ່</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowData && ShowData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y+1}</td>
                        <td >{x.name}</td>
                        <td >{x.surname}</td>
                        <td >{x.Tell}</td>
                        <td >{x.LocationName}</td>
                        <td >{x.Role}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.EmployeeID} type="button" onClick={() => openUpdate(x.EmployeeID, x.name, x.surname, x.username, x.Tell, x.loc, x.LocationName, x.roleID, x.Role)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </DataContext.Provider>
  );
};

export {DataContext};
export default Employee;
