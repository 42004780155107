import React, { useState, useContext, useRef, useEffect } from "react";
import useForm from "../../../../services/UseForm";
import '../../../../css/payment.css';
import '../../../../css/paymentBill.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { POSContent } from "../pos";
import { UserContext } from "../../../../App";
import { AlertContext } from "../../../../App";
import logo from '../../../../img/logo/qrcafe.jpg';
import { useReactToPrint } from 'react-to-print';

axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="PaymentBillContent">
                <div className="imgQR">
                  <img className="logo" src={logo} />
                </div>
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>BIC Coffee</span>
                    </div>
                    <div className="BillTitle2">
                        <span>By Manee Cafe</span>
                    </div>
                    <div className="Queue">
                        <span>BillNO # {billDetails.BillNO}</span>
                    </div>
                    <div className="Queue">
                        <span>Queue # {billDetails.QueueNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>

                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"></div>
                        <div className="footer-line"></div>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                return (
                                    <div className="Items" key={y}>
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className="Name">
                                            <span className="ProductName">{x.EngName}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="BillFooter">
                    <div className="footer-line"></div>
                    <div className="subtotal">
                        <span>SubTotal:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="discout">
                        <span>Discount:</span>
                        <strong>{currencyFormat(billDetails.DiscountAmount)}</strong>
                    </div>
                    <div className="nettotal">
                        <span>NetTotal:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="paymentType">
                        <span>PaymentType:</span>
                        <strong>{billDetails.PaymentType}</strong>
                    </div>
                    <div className="paidAmount">
                        <span>PaidAmount:</span>
                        <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
                    </div>
                    {
                        billDetails.PaidMoreAmount > 0 && 
                        <div className="PaidMoreAmount">
                            <span>PaidMoreAmount:</span>
                            <strong>{currencyFormat(billDetails.PaidMoreAmount)}</strong>
                        </div>
                        
                    }
                    <div className="changeAmount">
                        <span>Change:</span>
                        <strong>{currencyFormat(billDetails.ChangeAmount)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="TaxBeforeVat">
                        <span>Total Before VAT:</span>
                        <strong>{currencyFormat((Number(billDetails.NetTotal) - Number(billDetails.Tax) ))}</strong>
                    </div>
                    <div className="VAT">
                        <span>VAT(7%):</span>
                        <strong>{currencyFormat(billDetails.Tax)}</strong>
                    </div>
                    <div className="TotalTax">
                        <span>Total:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
}

function Payment(props) {

    const {QueueNO, setQueueData, setShowAlert, setErrorMessage, TempOrder, setTempOrder, PaymentTypeList, DiscountList, 
        showPayment, setShowPayment, subTotal, setSubTotal, NetTotal, setNetTotal, setShowOrder, Tax, setTax} = useContext(POSContent);
    const {userID, LocationID, userName, LocationName} = useContext(UserContext);
    const componentRef = useRef();

    const [TransactionID, setTransactionID] = useState("");
    const [PaymentTypeID, setPaymentTypeID] = useState();
    const [PaymentType, setPaymentType] = useState("");

    const [PaidAmount, setPaidAmount] = useState(0);
    const [PaidMoreAmount, setPaidMoreAmount] = useState(0);
    const [DiscountAmount, setDiscountAmount] = useState(0);
    const [ChangeAmount, setChangeAmount] = useState(0);
    const [DiscountPercent, setDiscountPercent] = useState(0);
    const [DiscountType, setDiscountType] = useState(0);
    const [disblebtnpayment, setDisblebtnpayment] = useState(1);
    
    const [TotalPaidAmount, setTotalPaidAmount] = useState(0);
    const [PaidAmountByCurrency, setPaidAmountByCurrency] = useState(0);

    const [InputClick, setInputClick] = useState(0);

    function currencyFormat(num) {
        if (num !== "") {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function getQueue() {
        axios
        .get("/getQueue")
        .then((response) => {
            let Data = response?.data;
            if(Data.length > 0)
                setQueueData(Data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const CalculateChangeAmount = (paidAmount, PaidMoreAmount, DiscountAmount) => {
        if (subTotal > 0) {
            var change = (Number(paidAmount) + Number(PaidMoreAmount) + Number(DiscountAmount)) - (Number(subTotal));
            var totalpaidAmount = (Number(paidAmount) + Number(PaidMoreAmount));
            setTotalPaidAmount(totalpaidAmount);
            setChangeAmount(change);
        }
    }

    const CalculateDiscount = (discountPercent) => {
        var gDiscountAmount = 0;
        var NewNetTotal = subTotal;
        var gIscount = (Number(subTotal) * (Number(discountPercent) / Number(100))).toFixed(0);
        var roundUp = gIscount.toString().substr(-3, 3);
        var tax = 0;
        if(roundUp > 500){
            gDiscountAmount = (Number(gIscount) - Number(roundUp)) + 1000;
            NewNetTotal = Number(subTotal) - Number(gDiscountAmount);
        } else if(roundUp < 500) {
            gDiscountAmount = (Number(gIscount) - Number(roundUp));
            NewNetTotal = Number(subTotal) - Number(gDiscountAmount);
        } else {
            gDiscountAmount = gIscount;
            NewNetTotal = Number(subTotal) - Number(gIscount);
        }
        tax = (Number(NewNetTotal) - ((Number(NewNetTotal) * 100) /107)).toFixed(2);
        setTax(tax);
        setDiscountAmount(gDiscountAmount);
        setNetTotal(NewNetTotal);
        CalculateChangeAmount(PaidAmount, PaidMoreAmount, gDiscountAmount);
    }

    const handleInputChange = event => {
        const {value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidAmount(value.split(',').join(''));
                CalculateChangeAmount(value.split(',').join(''), PaidMoreAmount, DiscountAmount);
            }  
        } else {
            setPaidAmount(0);
            CalculateChangeAmount(0, PaidMoreAmount, DiscountAmount);
        }
    };

    const handleInputClick = (e) => {
        setInputClick(e);
    };

    const hanldeclickMoney = (money) => {
        if(!InputClick){
            var totalpaidAmount = Number(PaidAmount) + Number(money);
            setPaidAmount(totalpaidAmount);
            CalculateChangeAmount(totalpaidAmount, PaidMoreAmount, DiscountAmount);
        } else {
            var totalpaidMoreAmount =Number(PaidMoreAmount) + Number(money);
            setPaidMoreAmount(totalpaidMoreAmount);
            CalculateChangeAmount(PaidAmount, totalpaidMoreAmount, DiscountAmount);
        }
    }

    const handleChangeDiscount = (event) => {
        const { value } = event.target;
        var tax = 0;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                var discount = Number(value.split(',').join(''))
                var NewNetTotal = Number(subTotal) - Number(discount);
                tax = (Number(subTotal) - ((Number(subTotal) * 100) /107)).toFixed(2);
                setDiscountAmount(discount);
                setNetTotal(NewNetTotal);
                setTax(tax);
                CalculateChangeAmount(PaidAmount, PaidMoreAmount, discount)
            }  
        } 
        else {
            tax = ((Number(subTotal) - (Number(subTotal) * 100) /107)).toFixed(2);
            setDiscountAmount(0);
            setNetTotal(subTotal);
            setTax(tax);
            CalculateChangeAmount(PaidAmount, PaidMoreAmount, 0)
        }
    }

    const handleInputMoreCashChange = event => {
        const { value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidMoreAmount(value.split(',').join(''));
                CalculateChangeAmount(PaidAmount, value.split(',').join(''), DiscountAmount);
            }  
        } else {
            setPaidMoreAmount(0);
            CalculateChangeAmount(PaidAmount, 0, DiscountAmount);
        }
    };

    const handleSelectDiscountType = (e) => {
        var data = DiscountList.filter(function (item) {
            return item.DiscountType == e.target.value
        })
        if (data.length > 0) {
            setDiscountPercent(data[0].Discount);
            CalculateDiscount(data[0].Discount);
            setDiscountType(data[0].DiscountType);
        } else {
            setDiscountPercent("");
            setDiscountType("");
            CalculateDiscount(0);
            setNetTotal(subTotal);
            CalculateChangeAmount(PaidAmount, PaidMoreAmount, 0)
        }
    };

    const handleSelectPaymentType = (e) => {
        var data = PaymentTypeList.filter(function (item) {
            return item.PaymentType === e.target.value
        })
        if (data.length > 0) {
            setPaidMoreAmount(0);
            CalculateChangeAmount(PaidAmount, 0, DiscountAmount);
            setPaymentTypeID(data[0].PaymentTypeID);
            setPaymentType(data[0].PaymentType);
        } else {
            setPaymentTypeID("");
            setPaymentType("");
        }
    };

    async function saveTutorial() {
        if(PaymentTypeID == ""){
            setErrorMessage("ກະລຸນາເລືອກປະເພດຈ່າຍ");
            setShowAlert(true);
        } else {
            if((Number(PaidAmount) + Number(PaidMoreAmount)) >= NetTotal){
                setDisblebtnpayment("")
                CreateQueue();
                CreateTransaction();
            } else {
                setErrorMessage("ຍອດຈ່າຍບໍ່ພຽງພໍ");
                setShowAlert(true);
            }
        }
    };

    async function CreateTransaction(){
        let token = localStorage.getItem("token");
        var data = {
            SubTotal: subTotal,
            Discount: DiscountAmount,
            NetTotal: NetTotal,
            PaidAmount: Number(TotalPaidAmount),
            PaidMoreAmount: PaidMoreAmount,
            ChangeAmount: ChangeAmount,
            PaymentTypeID: PaymentTypeID,
            LocationID: LocationID,
            Tax: Number(Tax),
            CreatedByID: userID,
            PaidAmountByCurrency: PaidAmountByCurrency,
            CreatedDate: new Date(),
            StatusID: 1,
        }
        await axios.post("/createTransaction", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            setTransactionID(response?.data?.insertId)
            handlePrint();
            CreateTransactionDetail(response?.data?.insertId);
            getQueue();
            ClearData();
        }).catch((err) => {
            console.log(err);
        });
    }

    async function CreateQueue () {
        let token = localStorage.getItem("token");
        var data = {
            QueueNO: Number(QueueNO) + Number(1)
        };
        await axios.post("/createQueue", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function CreateTransactionDetail(TransactionID) {
        let token = localStorage.getItem("token");
        if(TempOrder.length > 0) {
            for (var i=0; i < TempOrder.length; i++) {
                var list;
                list ={
                    TransactionID: TransactionID,
                    ProductID: TempOrder[i].ProductID,
                    Quantity: TempOrder[i].Quantity,
                    Retail_Price: TempOrder[i].Retail_Price,
                    SubTotal: TempOrder[i].SubTotal,
                };
                await axios.post("/createTransactionDetail", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                    getStoreInventory(TempOrder[i].ProductID, TempOrder[i].Quantity)
                }).catch((err) => {
                    console.log(err);
                });
            } 
            // ClearData();
        }
    };

    async function getStoreInventory (ProductID, Quantity) {
        let token = localStorage.getItem("token");
        await axios.get(`/getInventory/${ProductID}`).then((response) => {
            let inventoryData = response?.data;
            var gQuantity = 0;
            if(inventoryData.length > 0){
                gQuantity = Number(inventoryData[0].Quantity) - Number(Quantity);
                dropInventory(inventoryData[0].InventoryID, gQuantity);
            } 
        })
          .catch((error) => {
            console.log(error);
        });

    }

    async function dropInventory(InventoryID, qty) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: qty
        }
        await axios.put(`/updateInventory/${InventoryID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    }
    

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const ClearData = () => {
        const timeout = setTimeout(() => {
            setTransactionID("");

            setPaidAmount(0);
            setPaidMoreAmount(0);
            setDiscountAmount(0);
            setChangeAmount(0);

            setTotalPaidAmount(0);
            setPaidAmountByCurrency(0);
            
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
            setShowPayment(false);
            setShowOrder(false);
            setTempOrder([]);
            getQueue();
            setDisblebtnpayment(1);
        }, 3000);
        return () => clearTimeout(timeout);
    }

    const onClickClose = () => {
        setTransactionID("");

        setPaidAmount(0);
        setPaidMoreAmount(0);
        setDiscountAmount(0);
        setChangeAmount(0);

        setTotalPaidAmount(0);
        setPaidAmountByCurrency(0);
        setShowPayment(false);
    }

    return (
        <div style={{ display: showPayment ? 'flex' : 'none' }} className="paymentForm">
            <div className="PaymentContents">
                <div className="leftConten">
                    <div className="Header">
                        <span>ເລກຄີວ# {QueueNO}</span>
                    </div>
                    <div className="inputAmout">
                        <div className="selectContent"> 
                            <div className="PaymentType">
                                <label>ປະເພດຈ່າຍ</label>
                                <select className="PaymentType" onChange={(e) => handleSelectPaymentType(e)}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດຈ່າຍ'}
                                    </option>,
                                    {PaymentTypeList && PaymentTypeList.map(item => (
                                        <option
                                            key={item.PaymentTypeID}
                                            value={item.PaymentType}
                                        >
                                            {item.PaymentType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="DiscountType">
                                <label>ສ່ວນຫລຸດ</label>
                                <select className="DiscountType" onChange={(e) => handleSelectDiscountType(e)}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກສ່ວນຫລຸດ'}
                                    </option>,
                                    {
                                        DiscountList && DiscountList.map(item => (
                                        <option
                                            key={item.Discount}
                                            value={item.DiscountType}
                                        >
                                            {item.DiscountType}
                                        </option>
                                    ))}
                                </select>
                                {/* {errors.DiscountType && (
                                    <p className="invalid-feedback">{errors.DiscountType}</p>
                                )} */}
                            </div>
                        </div>
                        <div className="inputContent">
                            <div className="txtPaidAmount">
                                <label>ຍອດຈ່າຍ</label>
                                <input className={`input form-control`} name="PaidAmount" placeholder="ຈ່າຍ" required onClick={(e) => handleInputClick(0)} onChange={(e) => handleInputChange(e)} 
                                value={PaidAmount} disabled={PaymentType === "Gift Card" || PaymentType === "Member" || PaymentType === ""}  />
                                {/* {errors.PaidAmount && (
                                    <p className="invalid-feedback">{errors.PaidAmount}</p>
                                )} */}
                            </div>
                            <div className="txtPaidMore">
                                <label>ຈ່າຍເພີ່ມ</label>
                                <input className={`input form-control`} name="PaidMordAmount" placeholder="ຈ່າຍເພີ່ມເງິນສົດ" onClick={(e) =>handleInputClick(1)} required onChange={(e) => handleInputMoreCashChange(e)} 
                                value={PaidMoreAmount} disabled={!PaymentType || PaymentType == "Cash" || PaymentType == "CASH"} />
                                {/* {errors.PaidMoreAmount && (
                                    <p className="invalid-feedback">{errors.PaidMoreAmount}</p>
                                )} */}
                            </div>
                        </div>
                    </div>
                        <div className="inputMoneyContent">
                            <div className="group1">
                                <div className="money" onClick={(e) => hanldeclickMoney(500)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F500kip.jpg?alt=media&token=615c611e-5e0c-4c21-8bff-9273d0b20cc2" />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(1000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F1000kip.jpg?alt=media&token=04a8ef0d-12ce-45ba-8492-73d2d3e43cd5" />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(2000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F2000kip.jpg?alt=media&token=5264f1b8-c6c4-4122-8713-8e2be4cbe68d" />
                                </div>
                            </div>
                            <div className="group2">
                                <div className="money" onClick={(e) => hanldeclickMoney(5000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F5000kip.jpg?alt=media&token=cbfe39b5-0c0e-41ba-9f88-9a54249c25ab" />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(10000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F10000kip.jpg?alt=media&token=5264f1b8-c6c4-4122-8713-8e2be4cbe68d" />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(20000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F20000kip.jpg?alt=media&token=5264f1b8-c6c4-4122-8713-8e2be4cbe68d" />
                                </div>
                            </div>
                            <div className="group3">
                                <div className="money" onClick={(e) => hanldeclickMoney(50000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F50000kip.jpg?alt=media&token=5264f1b8-c6c4-4122-8713-8e2be4cbe68d" />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(100000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2F100000kip.jpg?alt=media&token=5264f1b8-c6c4-4122-8713-8e2be4cbe68d" />
                                </div>
                                {/* <div className="money" onClick={(e) => hanldeclickMoney(20000)}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/maneecafe-e8ef3.appspot.com/o/images%2Fkip%2Fvoucher.jpeg?alt=media&token=aff87b8c-8d9e-42c7-ab91-cdce5bd7ddc6" />
                                </div> */}
                            </div>
                        </div>
                    <div className="footer">

                    </div>
                </div>
                <div className="rightContent">
                    <div className="Header">
                        <div className="btnclose" onClick={e => onClickClose()}>X</div>
                    </div>
                    <div className="body">
                        <div className="subtotal">
                            <span>ຍອດລວມ:</span>
                            <strong>{currencyFormat(subTotal)}</strong>
                        </div>
                        <div className="discout">
                            <span>ສ່ວນຫລຸດ:</span>
                            <strong>{<input className={`input form-control`} name="Discount" placeholder="0" required onChange={(e) => handleChangeDiscount(e)} 
                                value={currencyFormat(DiscountAmount) || '0'} />}</strong> 
                        </div>
                        <div className="tax">
                            <span>Tax:</span>
                            <strong>{currencyFormat(Tax) || '0'}</strong>
                        </div>
                        <div className="nettotal">
                            <span>ຍອດລວມຕ້ອງຈ່າຍ:</span>
                            <strong>{currencyFormat(NetTotal) || '0'}</strong>
                        </div>
                        <div className="footer-line"></div>
                        <div className="paidAmount">
                            <span>ຍອດຈ່າຍ:</span>
                            <strong>{currencyFormat(PaidAmount) || '0'}</strong>
                        </div>
                        <div className="changeAmount">
                            <span>ເງິນທອນ:</span>
                            <strong>{currencyFormat(ChangeAmount) || '0'}</strong>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="btn-Payment">
                            <button className="btn" onClick={(e) => saveTutorial() } disabled={!disblebtnpayment}>ຈ່າຍເງິນ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                    TemProductList={TempOrder}
                    BillDetails={{"QueueNO": QueueNO, "BillNO": TransactionID, "cashier": userName, 
                    "LocationName": LocationName, "DiscountType": DiscountType, "PaymentType": PaymentType, "SubTotal": subTotal, "DiscountAmount": DiscountAmount, 
                    "Tax": Tax, "NetTotal": NetTotal, "PaidAmount": PaidAmount,"PaidMoreAmount": PaidMoreAmount, "ChangeAmount": ChangeAmount}} 
                />
            </div>
        </div >
    );
};

export default Payment;
