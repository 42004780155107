import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/product/menuValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { MenuContext } from "../menu";

axios.defaults.baseURL = API;

function CreateMenu(props) {

    const {MenuList, setMenuList, ProductType, setShowData, setShowAlert, setErrorMessage} = useContext(MenuContext);

    const handleSelectProductType = (e) => {
        const { value } = e.target;
        const results = ProductType.filter((data) => {
            return data.ProductType.toLowerCase() === value.toLowerCase()
        });
        if (results.length > 0){
            handleChange("ProductTypeID", results[0].ProductTypeID);
        } else {
            handleChange("ProductTypeID","");
        }
        check(value);
    };

    const check = (params) => {
        const results = MenuList.filter((data) => {
            return data.ProductType.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckProductType", results[0].ProductType);
        else
            handleChange("CheckProductType", "");
    }

    async function saveTutorial() {
        if(values.CheckProductType == ""){
            let token = localStorage.getItem("token");
            var data = {
                ProductTypeID: values.ProductTypeID,
                StatusID: 1
            }
            console.log(data)
            await axios.post("/CreateMenu", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                getMenu();
                ClearData();
                props.onClick(false);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setErrorMessage("ປະເພດສິນຄ້າແມ່ນມີຢູ່ແລ້ວ");
            setShowAlert(true);
        }
    };

    async function getMenu() {
        axios
          .get("/getmenu")
          .then((response) => {
            let Data = response?.data;
            setMenuList(Data);
            setShowData(Data);
            let DataString = JSON.stringify(Data);
            localStorage.setItem("menuData", DataString);
          })
          .catch((error) => {
            console.log(error);
        });
      }
    

    const ClearData = () => {
        handleChange("ProductType", "");
        handleChange("ProductTypeID", "");
        handleChange("CheckProductType", "");
    }
        

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງ ເມນູ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                            <label>ເມນູ: </label>
                                <select name="ProductTypeID" onChange={(e) => handleSelectProductType(e)}
                                    className={`input form-control ${errors.ProductTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດສິນຄ້າ'}
                                    </option>
                                    {   ProductType && ProductType.map(item => (
                                        <option
                                            key={item.ProductTypeID}
                                            value={item.ProductType}
                                        >
                                            {item.ProductType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateMenu;
