import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateMenu from "./create/createMenu";
import UpdateMenu from "./update/updateMenu";
import Alert from "../../../components/alert";
import { AlertContext } from "../../../App";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;
const MenuContext = createContext();

function Menu() {

  const {setShowAlert, setErrorMessage} = useContext(AlertContext);

  const [MenuList, setMenuList] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { value } = event.target;
    if(value){
      var results = [];
      results = MenuList.filter((data) => {
          return data.ProductType.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
      setShowData(results);
      else 
      setShowData(MenuList);
    } else 
    setShowData(MenuList);
  }

  const openUpdate = (MenuID, ProductType, ProductTypeID) => {
    var list = [];
    list.push({
      MenuID: MenuID,
      ProductType: ProductType,
      ProductTypeID: ProductTypeID,
    });
    setData(list);
    setShowUpdate(true);
  };

  async function CancleMenu (MenuID, ProductTypeID) {
    let token = localStorage.getItem("token");
    var data = {
        ProductTypeID: ProductTypeID,
        StatusID: 2
    }
    await axios.put(`/UpdateMenu/${MenuID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        getMenu();
    }).catch((err) => {
        console.log(err);
    });
  };

  async function getMenu() {
    axios
      .get("/getmenu")
      .then((response) => {
        let Data = response?.data;
        setMenuList(Data);
        setShowData(Data);
        let DataString = JSON.stringify(Data);
        localStorage.setItem("menuData", DataString);
      })
      .catch((error) => {
        console.log(error);
    });
  }
  
  useEffect(() => {
    let userData = localStorage.getItem("menuData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setMenuList(userRole);
      setShowData(userRole);
    }

    let producTypeData = localStorage.getItem("productTypeData");
    if (producTypeData) {
      let userRole = JSON.parse(producTypeData);
      setProductType(userRole);
    }
  }, []);

  return (
    <MenuContext.Provider value={{MenuList, setMenuList, setShowData, ProductType, setShowAlert, setErrorMessage, data}}>
      <div className="formContent">
        <CreateMenu show={show} onClick={handleClose}/>
        <UpdateMenu show={showUpdate} onClick={handleCloseUpdate}/>
        <Alert/> 
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ເມນູ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເມນູ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ProductType}</td>
                        <td >
                          {/* <button className="btn btn-secondary" value={x.MenuID} type="button" onClick={() => openUpdate(x.MenuID, x.ProductType, x.ProductTypeID)}>ແກ້ໄຂ</button>
                          &#160; */}
                          <button className="btn btn-danger" value={x.MenuID} type="button" onClick={() => CancleMenu(x.MenuID, x.ProductTypeID)}>ຍົກເລີກ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </MenuContext.Provider>
  );
};

export {MenuContext};
export default Menu;
